import React, {Component} from 'react';
import './ExhibitionList.sass';
import toBrake from "../lib/toBrake";

function openExhibition({title, url, width = 800, height = 600}) {
  const {width: sw, height: sh} = window.screen;
  window.open(
    url,
    title,
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=${height},top=${sh/2-height/2},left=${sw/2-width/2}`
  );
}

function ExhibitionItem(props) {
  return (
    <li onClick={() => openExhibition(props)}>
      <div className="exhibition-title">{toBrake(props.title)}</div>
      <div className="exhibition-description">{toBrake(props.description)}</div>
    </li>
  );
}

class ExhibitionList extends Component {
  state = {
    exhibitions: []
  };
  componentDidMount() {
    fetch("Exhibition.json")
      .then((response) => response.json())
      .then((data) => this.setState({ exhibitions: data['exhibitions'] }));
  }

  render() {
    const { exhibitions } = this.state;
    return (
      <ul className="exhibition-list">
        {
          exhibitions.map((i, k) => <ExhibitionItem key={k} {...i}/>)
        }
      </ul>
    );
  }
}

export default ExhibitionList;