import React, {Component} from 'react';
import './EventSchedule.sass'
import toBrake from "../lib/toBrake";
import Parser from 'html-react-parser';

function ScheduleItem(props) {
  if (props.title) {
    return (
      <li className="list-item" onClick={props.onClick}>{Parser(props.title)}</li>
    );
  }
  else if (props.section) {
    return (
      <li className="list-section" onClick={props.onClick}>{toBrake(props.section)}</li>
    );
  }
}

class EventSchedule extends Component {
  state = {
    schedule: []
  };
  componentDidMount() {
    fetch("Schedule.json")
      .then((response) => response.json())
      .then((data) => this.setState({ schedule: data['schedule'] }));
  }

  render() {
    const { schedule } = this.state;
    const { onSelect } = this.props;
    return (
      <ul className="schedule-list">
        {
          schedule.map((i, k) => <ScheduleItem key={k} onClick={() => onSelect(i)} {...i}/>)
        }
      </ul>
    );
  }
}


export default EventSchedule;
