import React from 'react';
import './Header.sass'
import Button from "./Button";
import {useSidebarDispatch, useSidebarState} from "../store/SidebarProvider";
import {useStreamInfo} from "../store/StreamInfoContext";
import Parser from 'html-react-parser';

export default function Header() {
	const sidebarDispatch = useSidebarDispatch();
	const sidebarState = useSidebarState();
	const streamInfo = useStreamInfo();

	return (
		<header className="header">
			<div className="header-left">
				<Button
					onClick={() => sidebarDispatch({ type: 'leftSidebarOpen', open: !sidebarState.leftSidebarOpened })}
				>
					{ sidebarState.leftSidebarOpened ? streamInfo["hide_program"] : streamInfo["show_program"] }
				</Button>
			</div>
			<div className="header-center">
				<span className="header-title">{Parser(streamInfo.header_title)}</span>
			</div>
			<div className="header-right">
				<Button
					onClick={() => sidebarDispatch({ type: 'rightSidebarOpen', open: !sidebarState.rightSidebarOpened })}
				>
					{ sidebarState.rightSidebarOpened ? streamInfo["hide_exhibition"] : streamInfo["show_exhibition"] }
				</Button>
			</div>
		</header>
  );
}