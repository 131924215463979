import React, {createContext, useContext, useReducer} from 'react';

const initialState = {
  leftSidebarOpened: true,
  rightSidebarOpened: false
};

const SidebarStateContext = createContext();
const SidebarDispatchContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'leftSidebarOpen':
      return { ...state, leftSidebarOpened: action.open };
    case 'rightSidebarOpen':
      return { ...state, rightSidebarOpened: action.open };
  }
}

export default function SidebarProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <SidebarStateContext.Provider value={state}>
      <SidebarDispatchContext.Provider value={dispatch}>
        { children }
      </SidebarDispatchContext.Provider>
    </SidebarStateContext.Provider>
  );
}

function useSidebarState() {
  return useContext(SidebarStateContext);
}

function useSidebarDispatch() {
  return useContext(SidebarDispatchContext);
}

export {useSidebarState, useSidebarDispatch};