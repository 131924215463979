import React from 'react';
import './Button.sass'

function Button(props) {
  return (
    <div>
      <button className="button" {...props}/>
    </div>
  );
}

export default Button;