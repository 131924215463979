import React, {Component} from 'react';
import './App.sass';
import Header from './Header';
import Main from "./Main";
import SidebarProvider from "../store/SidebarProvider";
import StreamInfoContext from "../store/StreamInfoContext";

class App extends Component {
  state = {
    stream_info: {
      "title": "",
      "header_title": "",
      "yt_url": "",
      "show_program": "Show program >",
      "hide_program": "< Hide program",
      "show_exhibition": "< Show exhibition",
      "hide_exhibition": "Hide exhibition >"
    }
  };
  componentDidMount() {
    fetch("LiveStream.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            stream_info: data
          },
          () => { document.title = this.state.stream_info.title }
          );
      });
  }

  render() {
    const {stream_info} = this.state;
    return (
      <div className="app">
        <SidebarProvider>
          <StreamInfoContext.Provider value={stream_info}>
            <Header/>
            <Main/>
          </StreamInfoContext.Provider>
        </SidebarProvider>
      </div>
    );
  }
}

export default App;
