import React, {useEffect, useRef, useState} from 'react';
import './YTPlayer.sass';
import YTPlayer from 'youtube-player';

function YtPlayer({url, time})  {
  const [player, setPlayer] = useState();
  const playerRef = useRef();
  useEffect(() => {
    setPlayer(YTPlayer(playerRef.current));
  }, []);
  useEffect(() => {
    if (time) {
      player.seekTo(time);
    }
  }, [time]);

  return (
    <div className="yt-player">
      <iframe ref={playerRef}
              style={{width: "100%", height: "100%"}}
              src={ url ? url + "?controls=1&amp;autoplay=1&amp;enablejsapi=1" : "" }
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen/>
    </div>
  );
}

export default YtPlayer;